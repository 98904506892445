import DataTable from "frappe-datatable";

class CustomDataTableProfitorLoss extends DataTable {
	render() {
		super.render();
		this.bodyScrollable.addEventListener('scroll', (e) => {
			// Your scroll event logic here
			if (this._settingHeaderPosition) return;

			this._settingHeaderPosition = true;

			requestAnimationFrame(() => {
				const left = -e.target.scrollLeft;
				var emt = this.getColumnHeaderElement(0);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(1);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				var tmes = this.get_body_scroll_fixed(0);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(1);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				this._settingHeaderPosition = false;
			});
		});
	}
	getColumnHeaderElement(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.header);
	}
	get_body_scroll_fixed(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.bodyScrollable);
		this.bodyScrollable
	}
};

class CustomDataTableTbwithPeriod extends DataTable {
	render() {
		super.render();
		this.bodyScrollable.addEventListener('scroll', (e) => {
			// Your scroll event logic here
			if (this._settingHeaderPosition) return;

			this._settingHeaderPosition = true;

			requestAnimationFrame(() => {
				const left = -e.target.scrollLeft;
				var emt = this.getColumnHeaderElement(0);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(1);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(2);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				var tmes = this.get_body_scroll_fixed(0);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(1);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(2);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				this._settingHeaderPosition = false;
			});
		});
	}
	getColumnHeaderElement(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.header);
	}
	get_body_scroll_fixed(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.bodyScrollable);
		this.bodyScrollable
	}
};

class CustomDataTableARandAP extends DataTable {
	render() {
		super.render();
		this.bodyScrollable.addEventListener('scroll', (e) => {
			// Your scroll event logic here
			if (this._settingHeaderPosition) return;

			this._settingHeaderPosition = true;

			requestAnimationFrame(() => {
				const left = -e.target.scrollLeft;
				var emt = this.getColumnHeaderElement(0);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(1);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(2);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(3);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(0);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(1);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(2);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(3);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				this._settingHeaderPosition = false;
			});
		});
	}
	getColumnHeaderElement(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.header);
	}
	get_body_scroll_fixed(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.bodyScrollable);
		this.bodyScrollable
	}
};
class CustomDataTablePOAnalysis extends DataTable {
	render() {
		super.render();
		this.bodyScrollable.addEventListener('scroll', (e) => {
			// Your scroll event logic here
			if (this._settingHeaderPosition) return;

			this._settingHeaderPosition = true;

			requestAnimationFrame(() => {
				const left = -e.target.scrollLeft;
				var emt = this.getColumnHeaderElement(0);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(1);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(2);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(3);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(4);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(0);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(1);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(2);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(3);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(4);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				this._settingHeaderPosition = false;
			});
		});
	}
	getColumnHeaderElement(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.header);
	}
	get_body_scroll_fixed(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.bodyScrollable);
		this.bodyScrollable
	}
};
class CustomDataTableItemWise extends DataTable {
	render() {
		super.render();
		this.bodyScrollable.addEventListener('scroll', (e) => {
			// Your scroll event logic here
			if (this._settingHeaderPosition) return;

			this._settingHeaderPosition = true;

			requestAnimationFrame(() => {
				const left = -e.target.scrollLeft;
				var emt = this.getColumnHeaderElement(0);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(1);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(2);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(3);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(4);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var emt = this.getColumnHeaderElement(5);
				emt.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(0);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(1);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(2);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(3);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(4);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});
				var tmes = this.get_body_scroll_fixed(5);
				tmes.each(function(index, elm) {
					elm.style.transform = `translateX(${-left}px)`;
					elm.style.zIndex = '10';

				});

				this._settingHeaderPosition = false;
			});
		});
	}
	getColumnHeaderElement(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.header);
	}
	get_body_scroll_fixed(colIndex) {
		colIndex = +colIndex;
		if (colIndex < 0) return null;
		return $(`.dt-cell--col-${colIndex}`, this.bodyScrollable);
		this.bodyScrollable
	}
};
frappe.provide("frappe.widget.utils");
frappe.provide("frappe.views");
frappe.provide("frappe.query_reports");
frappe.provide("frappe.views.QueryReport");
const orignal_quert_report = frappe.views.QueryReport.prototype;

frappe.views.QueryReport = class QueryReport extends frappe.views.QueryReport {
	add_card_button_to_toolbar() {
		// this.page.add_inner_button(__("Create Card"), () => {
		// 	this.add_card_to_dashboard();
		// });
		if (this.tree_report){
			this.page.add_inner_button(__("Collapse All"), () => {
				$(".expand-btn-hide").hide();
				if(cur_page.page._route != 'query-report/Leave Balance'){
					let depth = 1
					if (typeof this.report_settings.initial_depth == "number")
						depth = this.report_settings.initial_depth
					this.datatable.rowmanager.setTreeDepth(depth)
				}else{
					this.datatable.rowmanager.collapseAllNodes();
				}
				this.color_datatable();
				$(".expand-btn-show").show();
			}).addClass("expand-btn-hide btn-primary btn");

			this.page.add_inner_button(__("Expand All"), () => {
				$(".expand-btn-show").hide();
				this.datatable.rowmanager.expandAllNodes();
				this.color_datatable();
				$(".expand-btn-hide").show();
			}).addClass("expand-btn-show").hide();
		}

		this.page.add_inner_button(__("Hide Filters"), () => {
			$(".filter-btn-hide").hide();
			$(".page-form.flex").hide();
			$(".filter-btn-show").show();
		}).addClass("filter-btn-hide btn-primary btn");

		this.page.add_inner_button(__("Show Filters"), () => {
			$(".filter-btn-show").hide();
			$(".page-form.flex").show();
			$(".filter-btn-hide").show();
		}).addClass("filter-btn-show").hide();
	}
	render_datatable() {
		let data = this.data;
		let columns = this.columns.filter((col) => !col.hidden);

		if (this.raw_data.add_total_row && !this.report_settings.tree) {
			data = data.slice();
			data.splice(-1, 1);
		}

		this.$report.show();

        columns = this.add_empty_columns_if_needed(columns);

		if (
			this.datatable &&
			this.datatable.options &&
			this.datatable.options.showTotalRow === this.raw_data.add_total_row
		) {
			this.datatable.options.treeView = this.tree_report;
			this.datatable.refresh(data, columns);
		} else {
			let datatable_options = {
				columns: columns,
				data: data,
				inlineFilters: true,
				language: frappe.boot.lang,
				translations: frappe.utils.datatable.get_translations(),
				treeView: this.tree_report,
				layout: "fixed",
				cellHeight: 33,
				showTotalRow: this.raw_data.add_total_row && !this.report_settings.tree,
				direction: frappe.utils.is_rtl() ? "rtl" : "ltr",
				hooks: {
					columnTotal: frappe.utils.report_column_total,
				},
			};

			if (this.report_settings.get_datatable_options) {
				datatable_options = this.report_settings.get_datatable_options(datatable_options);
			}

			if (this.page_name == "query-report/Profit and Loss Statement") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Deferred Revenue and Expense") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Purchase Order Trends") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Sales Order Trends") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Sales Invoice Trends") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Asset Depreciations and Balances") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Employee Year to Date Statement") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Balance Sheet") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/P&L Accounting Dimension") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Cash Flow") {
				this.datatable = new CustomDataTableProfitorLoss(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Purchase Analytics") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Sales Analytics") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/CTC Master") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Employee Master") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/PF Report") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Income Tax Calculation") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Stock Ledger") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Timesheet Report") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Trial Balance with Period") {
				this.datatable = new CustomDataTableTbwithPeriod(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Purchase Register") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Salary Register") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Gross Based Salary Register") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Accounts Receivable with Cost Center") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Accounts Payable") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Employee Tax Declaration") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Monthly Attendance Sheet") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Employee Leave Balance Summary") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Payment Ledger") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/GST Sales Register") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/GST Purchase Register") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Received Items To Be Billed") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Item-wise Purchase History") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Sales Register") {
				this.datatable = new CustomDataTableARandAP(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Purchase Order Analysis") {
				this.datatable = new CustomDataTablePOAnalysis(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Sales Order Analysis") {
				this.datatable = new CustomDataTablePOAnalysis(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Item-wise Purchase Register") {
				this.datatable = new CustomDataTableItemWise(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Item-wise Sales Register") {
				this.datatable = new CustomDataTableItemWise(this.$report[0], datatable_options);
			}
			else if (this.page_name == "query-report/Accounts Receivable") {
				this.datatable = new CustomDataTableItemWise(this.$report[0], datatable_options);
			}
			else {
				this.datatable = new DataTable(this.$report[0], datatable_options);
			}
		}

		if (typeof this.report_settings.initial_depth == "number") {
			this.datatable.rowmanager.setTreeDepth(this.report_settings.initial_depth);
		}
		if (this.report_settings.after_datatable_render) {
			this.report_settings.after_datatable_render(this.datatable);
		}
	}

	setup_events() {
		super.setup_events();
		this.setup_filterable();
	}

	setup_filterable() {
		// filterable events
		this.clear_filters();

		$('.report-wrapper').on("click", ".filterable", (e) => {
			if (e.metaKey || e.ctrlKey) return;
			e.stopPropagation();
			const $this = $(e.currentTarget);
			const key = $this.attr("data-fieldname");
			const value = $this.attr("data-filter");
			this.set_filter_value(key, value);
		});
	}
	add_empty_columns_if_needed(columns) {
		let totalWidth = columns.reduce((acc, col) => acc + (col.width || 150), 1);
		let screenWidth = window.innerWidth;
		let emptyColumns = [];
	
		while (totalWidth < screenWidth) {
			emptyColumns.push({
				id: `empty-${emptyColumns.length}`,
				name: "",
				content: "",
				width: 150,
				editable:false,
			});
			totalWidth += 150;
		}
	
		if (totalWidth > screenWidth) {
			emptyColumns.pop();
		}
	
		return columns.concat(emptyColumns);
	}
	

}